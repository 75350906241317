/* FONT IMPORTS */
/* prettier-ignore */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://use.fontawesome.com/releases/v5.7.1/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap);

.icon {
  display: inline-block;

  font-family: 'Material Icons', serif;
  font-style: normal;
  line-height: 1;
  direction: ltr;
  letter-spacing: normal;
  white-space: nowrap;
  text-transform: none;
  vertical-align: middle;
  word-wrap: normal;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* VARIABLES */

:root {
  --main-color: #5dccab;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  position: relative;

  margin: 0;

  color: #fff;
  font-weight: 300;
  font-size: 1em;
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5;

  background: #252a48;
}

main {
  display: block;
  max-width: 50em;
  margin: 0;
  margin-top: 5em;
}

/* GRID */

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
}

.row-middle {
  align-items: center;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;

  text-align: left;
}

.col-center {
  justify-content: center;

  text-align: center;
}

.col-end {
  justify-content: flex-end;

  text-align: right;
}

/* Calendar */

.calendar {
  position: relative;
  position: fixed;
  bottom: 70px;

  display: block;
  width: 100%;
  height: 40vh;

  background: #e23d55;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.calendar .header {
  padding-top: 1rem;
  padding-bottom: 1rem;

  font-weight: 700;
  text-transform: uppercase;
}

.calendar .header .icon {
  cursor: pointer;

  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  color: var(--main-color);

  transform: scale(1.75);

  transition: 0.25s ease-out;
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  padding: 0.75em 0 0.75rem;

  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
}

.calendar .days div {
  font-weight: bold;
  text-align: right;
}

.calendar .body .cell {
  position: relative;

  height: 2.2em;
  overflow: hidden;

  background: #e23d55;
  cursor: pointer;

  transition: 0.25s ease-out;
}

.calendar .body .selected {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;

  color: #000;

  background: #fff;
  border-radius: 50%;
}

.calendar .body .row {
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  top: 0.75em;
  right: 0.75em;

  font-weight: 700;
  font-size: 11px;
  line-height: 1;
}

.calendar .body .disabled {
  color: #333;

  pointer-events: none;
}

.calendar .body .col span {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 25px;

  height: 25px;
}

.months {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */

  font-size: 14px;
}
.months::-webkit-scrollbar {
  display: none;
}
.months span {
  padding: 0 2rem;

  font-weight: light;
  font-size: 12px;

  cursor: pointer;
}

.selected-month {
  text-decoration: underline;
  text-underline-offset: 3px;
}

/* Activities */

.activities {
  /* height: 65vh; */
  padding-top: 2rem;
  padding-bottom: 7rem;

  font-size: 11px;
}

.activities-title {
  padding: 0 2rem 0;

  font-weight: bold;
}

.activities-container {
  display: flex;
  align-items: center;
  margin: 0.5rem 1rem;
  padding: 1rem;
}

.activity-type {
  display: flex;
  flex-basis: 20%;

  align-items: center;
}
.activity-type span {
  font-size: 2rem;
}

.activity {
  /* flex: 7; */
  display: flex;
  flex-basis: 60%;
  flex-flow: column;
}

.activities-personel {
  display: flex;
  flex-basis: 20%;

  align-items: center;
  justify-content: flex-end;
  margin-right: 0.5rem;
}

.activities-personel img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 5px;
}

.more-personel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  font-weight: bold;
  font-size: 1rem;

  background-color: #e23d55;
  border-radius: 5px;
}

.more-personel button {
  width: 40px;
  height: 40px;

  font-weight: bold;

  background-color: #e23d55 !important;
  border: none;
  border-radius: 5px;
}

.modal-header,
.modal-body,
.modal-footer {
  background-color: #252a48;
}

.modal-content {
  border: none;
}
.modal-body {
}

.modal-footer {
  border: none;
}
.close {
  color: #fff;
  text-shadow: none;

  opacity: 1;
}

/* .activity-odd {
  background-color: #1a1e33;
  border-radius: 10px;
} */

.App,
.calendar,
.bottom-navigation {
  max-width: 500px;
  margin: 0 auto;
}

.calendar-title-container {
  display: flex;
  justify-content: space-between;
}

.calendar-title {
  margin: 1.5rem 3rem 0;

  font-weight: bold;
  text-align: center;
}

a {
  color: #fff;
}

a:hover {
  color: #fff;
}

.activity-page {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 90vh;
  padding: 2rem 1.5rem;
}
.activity-title {
  margin: 0;

  font-weight: bold;
}

.activity-time {
  font-size: 13px;
}

.activity-info {
  /* font-size: 12px; */
  word-break: break-word;
}

.marginTopAuto {
  margin-top: auto;
}

.activity-personel {
  display: flex;
  /* height: calc(90% / 1); */
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 7rem;
}

.activity-personel img,
.activity-page .more-personel {
  width: 60px;
  height: 60px;
  /* margin-right: 0.75rem; */
  margin: 0.25rem;
  object-fit: cover;
  border-radius: 5px;
}

.more-personel:last-child {
  margin-right: 0;
}

.bottom-nav {
  position: fixed;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  height: 60px;

  font-size: 12px;
  /* padding: 0 1rem; */
  background-color: #e23d55;
}

.bottom-nav .link {
  flex: 1;
  margin: auto 0;
}

.bottom-nav .link div {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.events-icon,
.calendarz-icon,
.achievements-icon,
.profile-icon {
  margin-bottom: 5px !important;

  font-size: 1.5rem;
}

.home-icon img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px !important;
}

.home-logo {
  display: flex;
  margin-top: 2rem;
}
.home-logo img {
  width: 150px;
  margin: 0 auto;
}

.Login {
  padding: 3rem 0 1rem;
}

.Login form {
  max-width: 320px;
  margin: 0 auto;
}

.btn-custom {
  width: 100%;
  font-size: 1rem;
  margin: 1rem 0;
  background-color: #e23d55 !important;
  border-color: #e23d55 !important;
}
.btn-custom-jersey {
  font-size: 1rem;
  margin: 1rem 0;
  background-color: #e23d55 !important;
  border-color: #e23d55 !important;
  color: #fff;
  padding: unset;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  display: flex;
  /* margin: unset; */
  align-items: center;
  justify-content: center;
}

.input-label {
  margin-bottom: 0.25rem;

  font-size: 12px;
}

.btn:active:focus {
  box-shadow: 0 0 0 0.2rem #b43044 !important;
}
.btn:focus {
  box-shadow: 0 0 0 0.2rem #b43044 !important;
}

.form-control {
  font-size: 14px;
}

.signup-btn {
  max-width: 320px;
  margin: 0 auto 7rem;
}

.signup-btn button {
  width: 100%;
}
/* Solid border */
hr.solid {
  border-top: 1px solid rgba(187, 187, 187, 0.445);
}

.signup {
  max-width: 320px;
  margin: 0 auto;
  padding: 3rem 0 1rem;
}

.profile-page {
  max-width: 320px;
  margin: 0 auto;
  padding-top: 55px;
}

.profile-name {
  margin: 0;

  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
}

.profile-rank {
  margin-bottom: 1rem;
  text-align: center;
  color: #e23d55;
  font-weight: bold;
  text-transform: uppercase;
}

.give-award-icon {
  width: 50px;
  height: 50px;

  padding: 3px;

  fill: #ffd700;
}

.stats-icon,
.time-report-icon,
.profile-activities-icon,
.form-icon,
.megadoc-icon,
.timereport-icon,
.instagram-icon {
  width: 50px;
  height: 50px;

  padding: 3px;
}

.profile-page {
  position: relative;

  font-size: 12px;
}

.profile-menu {
  display: grid;
  gap: 1rem 1rem;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}

.profile-menu a {
  flex: 1;
  align-items: center;
  gap: 5px;
}

.time-report,
.give-award,
.stats,
.profile-activities,
.goodsport-form,
.megadoc,
.timereport,
.instagram {
  display: flex;
  flex-flow: column;
}

.dyvTIr {
  align-self: unset !important;

  color: #fff !important;
  font-weight: bold !important;
  font-size: 12px !important;
}

.bTMeAt {
  font-size: 12px !important;

  border-radius: 0.25rem !important;
}

.date-picker,
.time-picker,
.event-picker {
  margin: 0 auto;
}

#eventType {
  /* height: 40px; */

  font-size: 12px;
}

.iFGxeu {
  font-size: 12px !important;

  border-radius: 0.25rem !important;
}

.send-button {
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 7rem;
}

.cmXWMo {
  font-size: 12px !important;
}

.gJpzLG {
  border-radius: 0.25rem !important;
}

.btn-signout {
  position: absolute;
  top: 5px;
  right: 0;

  color: #fff;
  font-size: 12px;
}

.time-report-title {
  padding: 2rem;

  font-weight: bold;
}

.profile-info {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.profile-info img {
  width: 100px;
  /* height: ; */
  margin-right: 0.75rem;
  margin-bottom: 1rem;

  /* border-radius: 50%; */
}

.activities-container:nth-child(even) {
  background-color: #1a1e33;
  border-radius: 10px;
}

a:hover,
a {
  text-decoration: none !important;
}

.profile-image {
  position: relative;

  width: 150px;
  height: 150px;
  margin: 1rem auto;
}

.profile-image-preview {
  position: relative;

  width: 150px;
  height: 150px;
  margin: 0 auto 1rem 2rem;
}

.profile-image input,
.profile-image-preview input {
  position: absolute;
  opacity: 0;
  width: 150px;
  height: 150px;

  border-radius: 50%;
}
.profile-image .image,
.profile-image-preview .image {
  position: absolute;
  z-index: 100;

  width: 100%;

  height: 100%;
  object-fit: cover;

  pointer-events: none;
}
.profile-image .image.round,
.profile-image-preview .image.round {
  border-radius: 50%;
}

.activity-date {
  text-transform: capitalize;
}

.modal-leader {
  display: flex;
  align-items: center;
  width: 100%;

  font-size: 12px;
}
.modal-leader img {
  width: 50px;
  height: 50px;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  object-fit: cover;
  border-radius: 5px;
}

.modal-leader div {
  /* height: 72px; */
  margin-bottom: 0.75rem;
}

.btn-close {
  font-size: 14px;
}

.activity-page > p {
  margin: 0;
}

.activity-page p:nth-last-of-type(2) {
  margin-bottom: 1rem;
}

.activity-page .activity-title {
  margin-bottom: 1rem;
}

.activity-img {
  position: absolute;
  top: 2.5rem;
  right: 1.75rem;
}
.activity-img svg,
.activity-img img {
  width: 70px;
  height: 70px;
}

.timereg-logo svg,
.timereg-logo img {
  width: 30px;
  height: 30px;
  /* margin-right: 1rem; */
}

.i-worked {
  width: 30px;
  height: 30px;
}

.p-custom {
  font-size: 1.25rem;
}

.activities-timereg {
  /* height: 70vh; */
  padding-top: 2rem;
  overflow-y: auto;

  font-size: 12px;
}

.send-time {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0.5rem 1rem;
  padding: 1rem;
}

/*
.title-container {
  height: 60px;
  background-color: #1a1e33;
  position: fixed;
  max-width: 500px;
  width: 100%;
  display: flex;
  z-index: 2;
  padding-right: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid white;
}

.activities-timereg .activities-container:nth-child(2) {
  margin-top: 6rem;
} */

.timereg-info {
  padding: 0 2rem 0;
}

.link-activities {
  display: flex;
  flex: 1;
}

.more-personel-btn {
  width: 45px;
  height: 45px;

  font-weight: bold;

  background-color: #e23d55 !important;
  border: none;
  border-radius: 5px;
}

.dDVdjY,
.fzetqu {
  width: 90% !important;

  color: #fff !important;

  background-color: #1a1e33 !important;
  box-shadow: 0 2px 4px 0 #000 !important;
}

.jboJfB,
.lateRU {
  color: #fff !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  text-align: left !important;
}
.wKnna,
.dhYtct {
  padding: 1.5rem 1rem 0rem !important;
}

.hr-line {
  margin-top: 0;
  margin-bottom: 1rem;

  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.774);
}

.activities-container .modal-info {
  color: #fff;
  font-size: 14px;
}

.daOOLy,
.iLXmNF {
  background-color: unset !important;
  max-width: 500px !important;
  margin: 0 auto !important;
}

.activity-type div,
.activity-type h1 {
  padding-right: 0.5rem;
}

.activity-personel button {
  width: 60px;
  height: 60px;
  /* margin-right: 0.75rem; */
  margin: 0.25rem;

  font-weight: bold;

  background-color: #e23d55 !important;
  border-radius: 5px;
}
.cards {
  display: flex;

  /* Put a card in the next row when previous cards take all width */
  flex-wrap: wrap;

  margin-left: -8px;
  margin-right: -8px;
}

.cards__item {
  /* There will be 4 cards per row */
  flex-basis: 25%;

  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 375.1px) {
  .activity-personel img {
    width: 70px;
    height: 70px;
  }
  .activity-personel button {
    width: 70px;
    height: 70px;
  }
}

.loading-icon {
  margin: auto;
  padding-bottom: 10rem;
  width: 150px;
}

.heart-filled,
.heart-unfilled {
  width: 48px;
  height: 48px;
}

.heart-unfilled {
  filter: invert(1);
}

.attending-container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
  align-items: flex-end;
  margin-right: 0.25rem;
}

.stats-container {
  margin: 2rem 1rem;
}

.stats-title {
  padding: 0 2rem 0;
  font-weight: bold;
}

.doughnut-container {
  margin-top: 2rem;
}

.jersey-icon {
  width: 50px;
}

.teams-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.5rem 1rem;
}

.teams-container:nth-child(even) {
  background-color: #1a1e33;
  border-radius: 10px;
}

.team-setter {
  padding: 1rem 2rem 0;
  font-weight: bold;
}

.team-icon-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  cursor: pointer;
  height: 100%;
}

.team-icon {
  width: 100px;
}

.custom-margin {
  margin-top: unset;
  height: unset;
}

.lecture-container,
.alblebish-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  line-height: 1rem;
  font-size: 13px;
  position: relative;
}

.lecture-container h1 {
  margin: 0;
  font-weight: bold;
  color: #f7db1b;
  line-height: 1.4rem;
}
.lecture-info div {
  display: flex;
  align-items: center;
}

.lecture-container p {
  margin: 0.3rem;
}

@media (min-width: 550px) {
  .lecture-container h1 {
    line-height: 32px;
  }
}

.martin-img {
  max-height: 100px;
  border-radius: 5px;
  align-self: center;
  margin: 1rem;
  position: absolute;
  top: 50px;
  right: 20px;
}

.lecture-info {
  background-color: #1a1e33;
  padding: 1rem;
  margin: 1rem 0.5rem;
  border-radius: 10px;
  min-width: 90%;
}

.when,
.where {
  width: 20px;
  height: 20px;
}

.sc-laTMn.bVvIRa {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.sc-jzgbtB.dhYtct {
  display: inline-block;
  position: relative;
  margin-bottom: 1rem;
}

.sc-jzgbtB.dhYtct:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #d2d7da;
  width: 90%;
  transform: translateX(-50%);
  bottom: -15px;
  left: 50%;
}
